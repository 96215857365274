@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "insta";

@font-face {
	font-family: 'Helvetica Neue LT Pro';
	src: url('../../dist/font/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeueLTPro-Lt.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

html {
	background: $white;
	font-family: 'Helvetica Neue LT Pro', Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
	background-color: $black;
	position: relative;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

.btn {
	display: inline-block;
	background-color: $timberwolf;
	color: $fuscous-grey;
	font-size: 14px;
	border: none;
	border-radius: 7px;
	padding: 10px 24px 8px;
	text-transform: uppercase;
	letter-spacing: 1px;

	@include hover-focus {
		color: $timberwolf;
		background-color: $fuscous-grey;
		box-shadow: 0px 3px 20px 1px rgba(209, 155, 56, 0.5);
	}

	&.btn-outline {
		background-color: transparent;
		color: $orange;

		@include hover-focus {
			background-color: $orange;
			box-shadow: none;
		}
	}

	&.btn-big {
		font-size: 18px;

		@include tablet-up {
			font-size: 22px;
		}
	}

	&.btn-black {
		background-color: $fuscous-grey;
		color: $timberwolf;

		@include hover-focus {
			color: $fuscous-grey;
			background-color: $timberwolf;
			border: 1px solid $fuscous-grey;
			// box-shadow: none;
			box-shadow: 0px 3px 20px 1px rgba(209, 155, 56, 0.5);
		}
	}
}

h1,
h2,
h3,
p {
	margin: 0;
	font-style: italic;
}

h1,
h2,
h3 {
	font-family: cooper-black-std, serif;
	font-family: 'Times New Roman', Times, serif;
}

.heading {
	text-align: center;
	font-size: 42px;
	margin-bottom: 40px;
	text-transform: uppercase;

	@include tablet-up {
		font-size: 80px;
		margin-bottom: 80px;
	}
}

.social {
	@include flex(row, center, center);
	flex-wrap: wrap;

	a {
		color: $timberwolf;
		font-size: 20px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}

header {
	background-color: transparent;
	padding: 30px 0;
	margin: 0 16px;

	@include tablet-up {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

	}

	.container {
		display: grid;
		gap: 20px;
		text-align: center;
		justify-items: center;

		@include phone-up {
			padding: 0;
		}

		.header-logo {
			width: fit-content;
			max-width: 100%;
			margin: 0;
			text-align: center;
			color: $timberwolf;
			line-height: 1;
		}

		nav {
			@include flex(row, center, center);
			flex-wrap: wrap;

			a {
				display: block;
				font-size: 15px;
				text-transform: uppercase;
				margin-right: 20px;
				font-weight: 300;
				color: $timberwolf;

				&:last-child {
					margin-right: 0;
				}

				@include hover-focus {
					color: #c7a9a7;
				}
			}
		}

		.btn {
			font-size: 12px;
			padding: 10px 30px;
			width: 132px;
			max-width: 132px;
		}
	}

	@include tablet-up {
		padding: 10px 0;

		.container {
			position: relative;
			padding-left: 420px;
			padding-top: 25px;
			@include flex(row, flex-end, center);

			.header-logo {
				position: absolute;
				top: 10px;
				left: 0;
				font-size: 80px;
			}
		}
	}

	@media screen and (min-width:1300px) {
		padding: 20px 0;

		.container {
			padding-left: 680px;

			.header-logo {
				max-width: 662px;
			}
		}
	}
}

main {
	section {
		padding: 40px 0;

		@include tablet-up {
			padding: 100px 0;
		}

		&.hero {
			padding: 0;

			img {
				width: 100%;
			}
		}

		&.music {
			background-color: $fuscous-grey;

			.music-grid {
				display: grid;
				justify-items: center;
				gap: 20px;

				.music-item {
					overflow: hidden;
					position: relative;

					.sources {
						padding: 20px;
						transition: all .4s;
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: max-content;
						background-color: rgba(0, 0, 0, 0.685);
						@include flex(row, center, center);
						flex-wrap: wrap;

						a {
							font-size: 20px;
							margin-right: 20px;
							color: $orange;

							&:last-child {
								margin-right: 0;
							}

							@include hover-focus {
								color: $white;
							}
						}
					}

					img {
						transition: all .4s;
					}
				}
			}

			@include tablet-up {
				.music-grid {
					grid-template-columns: 1fr 1fr 1fr 1fr;

					.music-item {
						.sources {
							padding: 0;
							height: 100%;
							opacity: 0;
							pointer-events: none;
							z-index: 99;

							a {
								font-size: 30px;
							}
						}

						@include hover-focus {
							img {
								transform: scale(1.1);
							}

							.sources {
								opacity: 1;
								pointer-events: all;
							}
						}
					}
				}
			}
		}

		&.tour {
			background-color: $timberwolf;
			color: $fuscous-grey;


			.container {
				text-align: center;
			}

			.heading {
				color: $fuscous-grey;
			}

			#events {
				display: grid;
				text-align: center;

				.event {
					text-transform: uppercase;
					text-align: center;
					margin-bottom: 40px;

					&:last-child {
						// margin-bottom: 0;
					}

					.event-date {
						margin-bottom: 10px;
					}

					.event-location {
						margin-bottom: 5px;
					}

					.event-venue {
						margin-bottom: 10px;
					}

					.event-links-wrapper {
						min-width: 300px;
						display: flex;
						justify-content: flex-end;
						margin-left: 20px;

						@include tablet-down {
							flex-direction: column;
							justify-content: center;
							min-width: none;
							margin: 0;

							a {
								max-width: 120px;
								margin: 0 auto;
							}
						}
					}
				}
			}

			@include tablet-up {
				#events {
					.event {
						margin-bottom: 60px;
						@include flex(row, space-between, center);

						.event-date,
						.event-location,
						.event-venue,
						.btn {
							flex: 1;
						}

						.event-date,
						.btn {
							max-width: max-content;
						}
					}
				}
			}
		}

		&.about {
			background-color: $fuscous-grey;

			h2 {
				color: $timberwolf;
			}

			img {
				margin-bottom: 30px;
			}

			p {
				color: $timberwolf;
				line-height: 1.4;
				font-size: 18px;
				text-align: center;
			}

			h3 {
				color: $timberwolf;
				text-align: center;
				margin: 60px 0 25px;
				font-size: 28px;
				text-transform: uppercase;
				font-style: italic;
			}

			@include tablet-up {
				img {
					margin-bottom: 60px;
				}

				h3 {
					font-size: 36px;
					margin: 120px 0 50px;
				}
			}
		}


	}
}

footer {
	background-color: $fuscous-grey;
	padding: 50px 0;
	color: $black;

	.container {
		text-align: center;
	}

	h2 {
		color: $timberwolf;
		text-align: center;
		text-transform: uppercase;
		font-size: 40px;
	}

	.social {
		margin: 40px 0;

		a {
			color: $timberwolf;

			@include hover-focus {
				color: $dune;
			}
		}
	}

	.btn {
		text-align: center;
		color: $fuscous-grey;
	}

	.copyright {
		margin-top: 40px;
		text-align: center;
		font-size: 12px;
		line-height: 1.4;
	}

	@include tablet-up {

		// padding: 100px 0;
		h2 {
			font-size: 80px;
		}
	}
}

.newsletter-popup {
	opacity: 0;
	pointer-events: none;
	transition: all .4s;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.856);

	.container {
		height: 100vh;
		padding-top: 15px;
		padding-bottom: 15px;
		@include flex(row, center, center);

		.wrapper {
			position: relative;
			background-color: $white;
			color: $black;
			padding: 20px;
			border-radius: 10px;
			width: 100%;
			max-width: 600px;

			.close {
				background-color: transparent;
				border: none;
				padding: 0;
				line-height: 1;
				font-size: 20px;
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;

				@include hover-focus {
					color: $orange;
				}
			}

			h2 {
				font-size: 32px;
				text-align: center;
				margin-bottom: 40px;
			}

			form {
				input {
					border: solid 2px $black;
					padding: 20px;
					width: 100%;
					margin-bottom: 20px;
					text-transform: uppercase;
					letter-spacing: 1px;

					@include hover-focus {
						outline: none;
						border-color: $orange;
					}
				}

				input[type=submit] {
					display: inline-block;
					background-color: $orange;
					color: $white;
					font-size: 14px;
					border: solid 1px $black;
					border-radius: 5px;
					line-height: 1;
					padding: 20px 24px;
					text-transform: uppercase;
					letter-spacing: 1px;
					background-color: $black;
					color: $orange;
					font-size: 18px;

					@include tablet-up {
						font-size: 22px;
					}

					@include hover-focus {
						color: $white;
						box-shadow: none;
					}
				}
			}
		}
	}

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	@include tablet-up {
		.container {
			.wrapper {
				padding: 60px;

				.close {
					font-size: 30px;
					right: 20px;
					top: 20px;
				}
			}
		}
	}
}