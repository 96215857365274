@import "framework/framework";

#feed {
  display: grid;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @media screen and (min-width:500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include phone-up{
    grid-template-columns: repeat(3, 1fr);
  }
  @include tablet-up{
    grid-template-columns: repeat(4, 1fr);
  }

  > div {
    width: 100%;
    background-color: #ed713b;

    @media screen and (max-width: 800px) {
    flex-basis: 50%;
      &:nth-last-child(2), &:last-child{
        display: none;
      }
    }

    a {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative;
      width: 100%;

      span {
        align-items: center;
        background-color: rgba(0, 0, 0, .7);
        color: $white;
        display: flex;
        font-weight: 300;
        justify-content: center;
        line-height: 1.3;
        padding: 20px;
        font-size: 14px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        transition: transform .3s;
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        span {
          transform: translateX(0);
        }
      }
    }
  }
  @include phone-up{
    > div{
      a{
        span{
          display: flex;
        }
      }
    }
  }
}